angular.module('fingerink')
.directive('tarjeta', function () {
    return {
        restrict: 'E',
        replace: false,
        templateUrl: 'directives/tarjeta.tpl.html',
        scope: {
            tarjeta: '=',
            selected: '=',
            deleter: '&',
            click: '&',
            deleteable : '='
        }
    };
});
